<template>
	<div class="experience">
		<div class="top">
			<div class="top-img">
				<img v-lazy="topImg" :key="topImg" alt="top" />
			</div>
			<!-- <div class="top-footer" id="experienceId">
				<div
					class="btn-icon"
					v-for="(i, index) in iconList"
					:key="index"
					@click="getBtnIcon(i.id, i.path)"
				>
					<i :class="i.iconClass"></i>
					<p>{{ i.text }}</p>
				</div>
			</div> -->
		</div>
		<div class="container">
			<div class="content">
				<div class="inner">
					<div class="iconNav" id="experienceId">
						<div
							class="iconNav-item"
							v-for="(i, index) in iconList"
							:key="index"
							@click="getBtnIcon(i.id, i.path)"
						>
							<div class="iconNav-item-icon">
								<i :class="i.iconClass"></i>
							</div>

							<p>{{ i.text }}</p>
						</div>
					</div>
					<div class="main">
						<router-view />
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'experience',
	data() {
		return {
			// iconIndex: 0,
			topImg: require('@/assets/img/experience/experienceBg.jpg'),
			iconList: [
				{
					id: 0,
					iconClass: 'iconfont icon-a-zu69622',
					text: '语音识别合成',
					path: '/asr&vs',
				},
				{
					id: 1,
					iconClass: 'iconfont icon-a-caozuo-fanyi41',
					text: '机器翻译MT',
					path: '/translate',
				},
				{
					id: 2,
					iconClass: 'iconfont icon-a-mengbanzu4',
					text: '光学字符识别OCR',
					path: '/ocr',
				},
				{
					id: 3,
					iconClass: 'iconfont icon-a-zu697',
					text: '异常声音分析',
					path: '/voiceAnalyze',
				},
				{
					id: 4,
					iconClass: 'iconfont icon-a-rongqi75',
					text: '更多',
					path: '/more',
				},
			],
			scroll: '',
			htmlWidth: '',
		}
	},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	mounted() {
		this.htmlWidth = document.querySelector('html').offsetWidth
		// this.scroll = (this.htmlWidth / 1920) * 100 * 5.8;
		this.addActive(this.activeIndex)
		window.addEventListener('scroll', this.handleScroll)
	},
	watch: {
		activeIndex(val) {
			this.addActive(val)
		},
		$route() {
			this.$nextTick(() => {
				document.body.scrollTop = this.scroll
				document.documentElement.scrollTop = this.scroll
			})
		},
	},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
		addActive(num) {
			let domIcon = document.querySelector('.iconNav').children
			let btnIcon = Array.from(domIcon)
			btnIcon.forEach((e) => {
				e.classList.remove('active')
			})
			btnIcon[num].classList.add('active')
		},
		getBtnIcon(id, path) {
			if (path) {
				this.$router.push(path)
			}
			this.activeIndex = id
		},
		handleScroll() {
			// this.scroll =
			//   document.documentElement.scrollTop || document.body.scrollTop;//按照历史滑动条位置保存
			this.scroll = (this.htmlWidth / 1920) * 100 * 6.4
		},
	},
	components: {},
	computed: {
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
}
</script>
<style lang="scss" scoped>
.experience {
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	// background-color: #eee;
	.top {
		width: 100%;
		overflow: hidden;
		height: 6.76rem;
		position: relative;

		.top-img {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
		position: relative;
		padding-top: 2.58rem;
		padding-bottom: 3rem;
	}
	.content {
		.iconNav {
			width: 100%;
			height: 2.7rem;
			position: absolute;
			top: -1.12rem;
			text-align: center;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.iconNav-item {
				width: 2.4rem;
				height: 100%;
				background: linear-gradient(
					180deg,
					rgba(241, 245, 250, 1),
					rgba(255, 255, 255, 1)
				);
				color: #485163;
				box-shadow: 0 4px 10px 0px #d1ddeb;
				border-radius: 0.12rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				&-icon {
					width: 0.96rem;
					height: 0.96rem;
					background-color: #eff6ff;
					border-radius: 50%;
					line-height: 0.96rem;
					margin-bottom: 0.24rem;
					i {
						font-size: 0.6rem;
						color: #127bf2;
					}
				}
				p {
					font-size: 0.24rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 700;
					color: #485163;
					line-height: 0.32rem;
				}
				&:hover {
					background: #127bf2;
					p {
						color: #ffffff;
					}
				}
			}
			.active {
				background: #127bf2;
				p {
					color: #ffffff;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.experience {
		.top {
			.top-footer {
				width: 90%;
			}
		}
		.content {
			width: 90%;
		}
	}
}
</style>
